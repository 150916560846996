@font-face {
  font-family: 'Klukva';
  src: url(./fonts/Klukva_version_01.otf);
  /*  "Klukva" font*/
}

:root {
  margin: 0;
  font-family: 'Klukva', sans-serif !important;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
