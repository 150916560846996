$medium: 900px


.AppHeader
    //some CSS
    @media screen and (min-width: $medium)
        height: 700px
body
    margin: 0

.AppHeaderVideo
    object-fit: cover
    justify-content: left
    position: absolute
    top: 0
    left: 0
    width: 100%
.AppHeader
    width: 100%
    min-height: 350px
    position: relative
    overflow: hidden
    text-align: center
    display: flex
    align-items: center
    justify-content: center

.AppHeaderDiv
    position: relative
    color: white
    text-shadow: 1px 1px 8px rgba(0,0,0,0.6)
.AppHeaderH1
    font-size: 4em

//.flexPanel
//    display: flex
//    flex-direction: row
//    justify-content: space-between

.flexPanel
    @media screen and (max-width: $medium)

    @media screen and (min-width: $medium)
        margin: 30px
        display: flex
        flex-direction: row
        justify-content: space-between



.SwipeableTextMobileStepper
    @media screen and (max-width: $medium)

    @media screen and (min-width: $medium)
        width: 50vw
        margin-right: 30px

.BasicTabs
    @media screen and (max-width: $medium)

    @media screen and (min-width: $medium)
        width: 90vw



.QuiltedImageList
    height: 100%
    margin: 0 0 1em 0
    max-width: 600px


    @media screen and (max-width: $medium) // for phone

    @media screen and (min-width: $medium) // for PC



.DivActionAreaCard
    @media screen and (max-width: $medium)
        justify-content: space-around
        display: flex
        flex-direction: column
        align-items: center

    @media screen and (min-width: $medium)
        padding: 0 20px 0 20px
        max-width: 900px
        display: flex
        flex-direction: row
        flex-wrap: wrap
        float: left
        justify-content: space-between
        div:nth-of-type(1)
            margin-top: 0

//.AppHeader
//    position: relative
//.AppHeaderVideo
//    position: absolute
//    z-index: 0
//.AppHeaderH1
//    background-color: rgba(0, 0, 0, 0.35)
//    position: absolute
//    z-index: 1
//    color: white
//    font-size: 10em
//    left: 40%
//    bottom: 40%
//
//    padding: 1em
//    border-radius: 0.5em

@font-face
    font-family: 'Klukva'
    src: url(./fonts/Klukva_version_01.otf)
    /*  "Klukva" font*/


root
    margin: 0
    font-family: 'Klukva', sans-serif
    -webkit-font-smoothing: antialiased
    -moz-osx-font-smoothing: grayscale


